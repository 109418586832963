<template>
  <b-container>
    <masthead title="More" />
    <b-list-group id="mobile-more-container">
      <b-list-group-item :to="{name: 'registrations'}" class="d-flex justify-content-between align-items-center">
        Registrations
        <i>
          <feather-icon type="chevron-right" />
        </i>
      </b-list-group-item>
      <b-list-group-item
        :to="{name: 'companySelection'}"
        class="d-flex justify-content-between align-items-center"
      >
        Forms Library
        <i>
          <feather-icon type="chevron-right" />
        </i>
      </b-list-group-item>
      <b-list-group-item
        v-if="false"
        :to="{name: 'savedDrafts'}"
        class="d-flex justify-content-between align-items-center"
      >
        Saved Progress
        <i>
          <feather-icon type="chevron-right" />
        </i>
      </b-list-group-item>
    </b-list-group>
  </b-container>
</template>

<script>
import FeatherIcon from '@/components/shared/FeatherIcon'
import Masthead from '@/components/shared/Masthead'

export default {
  name: 'MobileMore',
  components: { FeatherIcon, Masthead },
  computed: {
    token: function() {
      return (
        this.$store.state.session.token != undefined &&
        this.$store.state.session.token != null
      )
    },
  },
  mounted: function() {
    //
  },
}
</script>

<style lang="scss" scoped>
#mobile-more-container {
  .list-group-item:first-child {
    border-top: 0;
  }

  .list-group-item {
    font-size: 18px;
    border-right: 0;
    border-left: 0;
  }
}
</style>

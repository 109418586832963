var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c("masthead", { attrs: { title: "More" } }),
      _c(
        "b-list-group",
        { attrs: { id: "mobile-more-container" } },
        [
          _c(
            "b-list-group-item",
            {
              staticClass: "d-flex justify-content-between align-items-center",
              attrs: { to: { name: "registrations" } },
            },
            [
              _vm._v("\n      Registrations\n      "),
              _c(
                "i",
                [_c("feather-icon", { attrs: { type: "chevron-right" } })],
                1
              ),
            ]
          ),
          _c(
            "b-list-group-item",
            {
              staticClass: "d-flex justify-content-between align-items-center",
              attrs: { to: { name: "companySelection" } },
            },
            [
              _vm._v("\n      Forms Library\n      "),
              _c(
                "i",
                [_c("feather-icon", { attrs: { type: "chevron-right" } })],
                1
              ),
            ]
          ),
          false
            ? _c(
                "b-list-group-item",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center",
                  attrs: { to: { name: "savedDrafts" } },
                },
                [
                  _vm._v("\n      Saved Progress\n      "),
                  _c(
                    "i",
                    [_c("feather-icon", { attrs: { type: "chevron-right" } })],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }